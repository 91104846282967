<template>
  <div>
    <l-button
      v-auth="'AUTH_COMPANY-SAVE'"
      type="primary"
      class="c-top_right_btn"
      @click="editRow()"
    >
      新建集团
    </l-button>

    <!-- 列表区域-STR -->
    <l-table-common
      :data-source="tableData"
      @change="search"
    >
      <l-table-column data-index="id" title="流水号" />
      <l-table-column data-index="name" title="集团名字" />
      <l-table-column data-index="shortName" title="集团别名" />
      <l-table-column data-index="account" title="账号" />
      <l-table-column title="创建时间">
        <template slot-scope="scope">
          {{ scope.createTime | date }}
        </template>
      </l-table-column>
      <l-table-column data-index="createName" title="创建人" />

      <l-table-column title="更多">
        <template v-if="scope.type !== 0" slot-scope="scope">
          <l-button
            v-auth="'AUTH_COMPANY-SAVE'"
            type="link"
            @click="editRow(scope)"
          >
            编辑
          </l-button>
        </template>
      </l-table-column>
    </l-table-common>
    <!-- 列表区域-END -->

    <!-- 保存记录-STR -->
    <l-modal
      v-model="editDialog.show"
      :title="editDialog.data.id?'修改集团':'添加集团'"
      width="600px"
      cancel-text="取消"
      ok-text="确认"
      @ok="saveDialog"
    >
      <l-form-model
        ref="form"
        :model="editDialog.data"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 16 }"
      >
        <l-form-model-item
          v-if="!editDialog.data.id"
          label="集团名字"
          prop="name"
          required
        >
          <l-input
            v-model="editDialog.data.name"
            placeholder="请输入集团名字"
          />
        </l-form-model-item>
        <l-form-model-item
          v-if="!editDialog.data.id"
          label="集团简称"
          prop="shortName"
          required
        >
          <l-input
            v-model="editDialog.data.shortName"
            placeholder="请输入集团简称"
          />
        </l-form-model-item>
        <l-form-model-item
          v-if="!editDialog.data.id"
          label="集团账号"
          prop="account"
          required
        >
          <l-input
            v-model="editDialog.data.account"
            placeholder="请输入集团账号"
          />
        </l-form-model-item>
        <l-form-model-item label="appId" prop="appId">
          <l-input
            v-model="editDialog.data.ext.appId"
            placeholder="请输入集团appId"
          />
        </l-form-model-item>
        <l-form-model-item label="appSecret" prop="appSecret">
          <l-input
            v-model="editDialog.data.ext.appSecret"
            placeholder="请输入集团appSecret"
          />
        </l-form-model-item>
        <l-form-model-item
          v-if="!editDialog.data.id"
          label="关联系统"
          prop="appId"
          required
        >
          <l-select
            v-model="editDialog.data.appId"
            placeholder="请选择系统"
          >
            <l-select-option
              v-for="item in authAppList"
              :key="item.id"
              :value="item.id"
            >
              {{ item.cnName }}
            </l-select-option>
          </l-select>
        </l-form-model-item>
      </l-form-model>
    </l-modal>
    <!-- 保存记录-END -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'AuthAppList',
  data() {
    return {
      tableData: {
        isLoading: false,
        total: 0,
        page: 1,
        size: 15,
        list: []
      },
      editDialog: {
        data: {
          name: '',
          shortName: '',
          account: '',
          appId: '',
          ext: {
            appId: '',
            appSecret: ''
          }
        },
        save: false,
        show: false
      }
    }
  },
  computed: {
    ...mapState({
      authAppList: state => state.auth.authAppList
    })
  },
  mounted() {
    this.search(1)
    this.$store.dispatch('auth/authAppList')
  },
  methods: {
    async search(page) {
      page = (page && page.current) || page || this.tableData.page
      const param = {
        page: page || 1,
        size: this.tableData.size
      }
      this.tableData.list = []
      this.tableData.isLoading = true
      try {
        const data = await this.$store.dispatch('auth/authCompanyPage', param)
        this.tableData.isLoading = false
        this.tableData.list = data.list || []
        this.tableData.total = data.count
        this.tableData.page = page || 1
      } catch (e) {
        this.tableData.isLoading = false
      }
    },
    resetForm() {
      this.search(1)
    },
    editRow(row) {
      row = row || this.$options.data().editDialog.data
      row.ext = row.ext || this.$options.data().editDialog.data.ext
      this.editDialog.data = JSON.parse(JSON.stringify(row))
      try {
        this.editDialog.data.ext = JSON.parse(this.editDialog.data.ext)
      } catch (e) {
        console.info(e)
      }
      for (const key in this.editDialog.errmsg) {
        this.editDialog.errmsg[key] = ''
      }
      this.editDialog.show = true
    },
    async saveDialog() {
      this.editDialog.save = true
      let data = this.editDialog.data
      try {
        data = JSON.parse(JSON.stringify(data))
        data.ext = JSON.stringify(data.ext)
      } catch (e) {
        console.info(e)
      }
      await this.$store.dispatch('auth/authCompanySave', data)
      this.editDialog.show = false
      this.search()
    }
  }
}
</script>

<style lang="scss">
.system-index-btns {
  text-align: right;
  & i {
    font-size: 12px;
    margin-right: 8px;
    vertical-align: top;
  }
  & span {
    display: inline-block;
    vertical-align: top;
  }
}
.device-info {
  .device-info-body {
    margin: 15px 15px 70px;
  }
  .l-dialog__header {
    border-bottom: 1px solid $l-border-base;
  }
  .info-line {
    margin-bottom: 10px;
    span {
      display: inline-block;
      padding: 5px 0;
      color: $l-text-primary;
      font-size: 14px;
      line-height: 24px;
    }
    span.name {
      min-width: 110px;
      line-height: 24px;
    }
  }
}
</style>
